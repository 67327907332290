import restaurant from "./images/gril.jpg";

import img_card from "./images/fff.jpg";
import imgheadermb from "./images/fonmb.png";
import imgheader from "./images/fonheader.png";
import imgheadermiini from "./images/h14.jpg";
import imgheadermiinimb from "./images/h14.jpg";
import logo from './images/logo.png'
import imgmikey from './images/inv.png'
import imgmikeym from './images/invm.jpg'

const data = {
    introData: [{
        copilul: "Mira",
        familia: "fam. Blanari",
        logo: logo,
        tata: "Vadim",
        mama: "Cristina",
        data: "17 August 2024",
        data_confirmare: "15 iulie 2024",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "rotacrys89@gmail.com", 
       tel: "+37362098273",
       phone: "tel:+37362098273",
       viber: "viber://chat?number=%2B37362098273",
       whatsapp: "https://wa.me/+37362098273",
       messenger: "https://www.messenger.com/t/xxx",
       tel1: "+373 697 40 712",
       phone1: "tel:+37369740712",
       viber1: "viber://chat?number=%2B37369740712",
       whatsapp1: "https://wa.me/+37369740712",
       messenger1: "https://www.messenger.com/t/petru.bucatari",
    }],
    cardData: [
   
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Gril Parc",
            data: "17 august 2024, sâmbătă, ora 18:00",
            adress: "Traseul Chisinau - Leuseni -33 Km",
            harta: "https://maps.app.goo.gl/D3ugAE4pcsh4Yd2C8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10871.358283904752!2d28.5304384!3d47.0629973!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cbd2713fb70e2b%3A0xba5c548af7ad2d20!2sGril%20Parc!5e0!3m2!1sro!2s!4v1718476982047!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copiii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, sunt pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],
    blogCardInv: [
        {
            mikey: imgmikey,
            mikeym: imgmikeym,
            
        }
    ],
}

export default data;